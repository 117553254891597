<template>
  <div class="reason-list-wrap">
    <el-form v-loading="loading" ref="formRef" :model="form" :rules="rules" style="width: 100%">
      <div class="top-block">
        <div class="title">文案設定</div>
        <el-button type="primary" style="width: 110px" @click="onSubmit(formRef)" :disabled="isSame()">儲存</el-button>
      </div>

      <div class="reason-title-wrap">
        <div class="reason-title">拒單原因</div>
        <div class="green-hint">顯示於 POS 視窗，上限 10 字</div>
      </div>

      <el-form-item>
        <el-col
          :span="4"
          v-for="(reject, index) in reasonList.rejectList.length"
          :key="index"
          style="margin-right: 10px"
        >
          <el-form-item :prop="`rejectReason${index + 1}`">
            <el-input v-model="form[`rejectReason${index + 1}`]" placeholder="請輸入原因" />
          </el-form-item>
        </el-col>
      </el-form-item>

      <div class="reason-title-wrap">
        <div class="reason-title">延遲訂單原因</div>
        <div class="green-hint">顯示於 POS 視窗，上限 10 字</div>
      </div>

      <el-form-item>
        <el-col :span="4" v-for="(delay, index) in reasonList.delayList.length" :key="index" style="margin-right: 10px">
          <el-form-item :prop="`delayReason${index + 1}`">
            <el-input v-model="form[`delayReason${index + 1}`]" placeholder="請輸入原因" />
          </el-form-item>
        </el-col>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { ref, reactive, getCurrentInstance } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useGetters, useActions } from '@/utils/mapStore'; // useMutations,

export default {
  name: 'reason-list',
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const loading = ref(false);
    const formRef = ref(null);
    const reasonList = reactive({
      rejectList: [],
      delayList: [],
    });
    const form = reactive({
      rejectReason1: '',
      rejectReason2: '',
      rejectReason3: '',
      rejectReason4: '',
      rejectReason5: '',
      delayReason1: '',
      delayReason2: '',
      delayReason3: '',
      delayReason4: '',
      delayReason5: '',
    });

    //取得資料
    const getReasonList = useGetters('systemPreferences', ['getReasonList']);
    const { doGetReasonList, doSaveReasonList } = useActions('systemPreferences', [
      'doGetReasonList',
      'doSaveReasonList',
    ]);

    //初始化
    async function init() {
      await doGetReasonList();
      reasonList.rejectList = getReasonList.value.order_reject_reason;
      reasonList.rejectList.forEach((data, index) => {
        form[`rejectReason${index + 1}`] = data;
      });
      reasonList.delayList = getReasonList.value.order_delay_reason;
      reasonList.delayList.forEach((data, index) => {
        form[`delayReason${index + 1}`] = data;
      });
    }
    init();

    // 規則: 每個原因字數上限10字
    function limit10char() {
      return [{ max: 10, message: 'limit 10', trigger: 'blur' }];
    }
    const rules = reactive({
      rejectReason1: limit10char(),
      rejectReason2: limit10char(),
      rejectReason3: limit10char(),
      rejectReason4: limit10char(),
      rejectReason5: limit10char(),
      delayReason1: limit10char(),
      delayReason2: limit10char(),
      delayReason3: limit10char(),
      delayReason4: limit10char(),
      delayReason5: limit10char(),
    });

    // 是否未修改
    function objToArr() {
      let arr1 = [];
      let arr2 = [];
      for (let i = 0; i < reasonList.rejectList.length; i++) {
        arr1.push(form[`rejectReason${i + 1}`]);
      }
      for (let i = 0; i < reasonList.delayList.length; i++) {
        arr2.push(form[`delayReason${i + 1}`]);
      }
      return {
        order_reject_reason: arr1,
        order_delay_reason: arr2,
      };
    }
    function isSame() {
      let checkObj = objToArr();
      let reject = JSON.stringify(checkObj.order_reject_reason) == JSON.stringify(reasonList.rejectList);
      let delay = JSON.stringify(checkObj.order_delay_reason) == JSON.stringify(reasonList.delayList);

      if (reject == true && delay == true) return true;
      else return false;
    }

    // 送出資料
    const onSubmit = async (form) => {
      try {
        const checkForm = await form.validate();
        // console.log("validate---",checkForm)
        if (!checkForm) return;
        else {
          loading.value = true;
          await doSaveReasonList(objToArr());
          await init();
          loading.value = false;
          proxy.$message({
            type: 'success',
            message: '儲存成功',
          });
        }
      } catch (e) {
        console.log('error-', e);
      }
    };

    // 離開檢查是否需要儲存
    onBeforeRouteLeave((to, from, next) => checkSave(next));
    const checkSave = (next) => {
      if (isSame() == false) {
        proxy
          .$confirm(`變更尚未儲存，確定要離開此頁？`, '尚未儲存', {
            confirmButtonText: '離開',
            cancelButtonText: '取消',
            type: 'warning',
            confirmButtonClass: 'el-button--danger',
          })
          .then(() => {
            if (next) next();
            else router.go(-1);
          })
          .catch((e) => e);
      } else {
        if (next) next();
        else router.go(-1);
      }
    };

    return { formRef, form, rules, onSubmit, reasonList, isSame, loading };
  },
};
</script>
<style lang="scss" scoped>
.reason-list-wrap {
  @include white-bg;
  display: flex;
  align-items: center;
  padding: 16px 22px;
  position: relative;
}
.top-block {
  width: 100%;
  @include flex-between-center;
  .title {
    font-size: 16px;
  }
}
.reason-title-wrap {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 10px;
  .reason-title {
    font-size: 15px;
    margin-right: 10px;
  }
}
</style>
